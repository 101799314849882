.add-employees-heading {
  display: flex;
  width: 100%;
}
.add-employees-btn {
  justify-content: center;
  display: flex;
  width: 100%;
  align-items: center;
}

.image-main-div {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: auto;
}
.image-inner-div {
  width: 100%;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 120px;
}

.image-icon {
  padding: 5px;
  border-radius: 50%;
  background: #fb7645;
  color: white;
  position: absolute;
  bottom: 0px;
}

@media only screen and (max-width: 900px) {
  .image-inner-div {
    width: fit-content;
    width: -moz-fit-content;
  }
}
