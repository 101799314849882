.layout-main {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}
.layout-main-inner {
  display: flex;
  align-items: center;
  width: 100%;
}
