.image {
  width: 100%;
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.contact-main {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  width: 100%;
}
.whatsapp-button {
  position: fixed;
  bottom: 20px;
  right: 20px;
  z-index: 1000;
}
.icon {
  background: white;
  color: #25d366;
  width: 50px;
  height: 50px;
  border-radius: 5%;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}
.map {
  border: 0;
  width: 100%;
  border-radius: 5;
  padding: 1rem;
  height: 70vh;
}
.about-main,
.about-main1 {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  width: 100%;
}

.about-main > p,
.about-main1 > p {
  text-align: justify;
  padding: 1rem;
}
.about-main > img,
.about-main1 > img {
  width: 50%;
  height: 50%;
  padding: 1rem;
}
.about-main1 > ul > li > span {
  font-weight: bold;
}
/* media query for 900px */
@media only screen and (max-width: 900px) {
  .contact-main {
    flex-direction: column;
    justify-content: center;
    order: -1;
  }
  .map {
    height: 50vh;
  }
  .about-main,
  .about-main1 {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .about-main1 > p {
    order: -1;
  }
  .about-main > img,
  .about-main1 > img {
    width: 70%;
    height: 70%;
    padding: 1rem;
  }
}
