.landing-footer-class {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 2rem;
  background: #353935;
}
.landing-footer-inner {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 20%;
  color: whitesmoke;
  cursor: pointer;
}

.landing-footer-content {
  padding: 0.5rem;
  font-size: 1rem;
  font-weight: 600;
  justify-content: left;
  align-items: center;
  display: flex;
  width: 100%;
  text-align: left;
}
.landing-footer-content > span {
  margin: 0.5rem 0.5rem 0rem 0rem;
  color: white;
}
.landing-footer-content > span > a {
  color: white;
}

.img > img {
  width: 100%;
  height: 100%;
}
/* media query for 900px */
@media only screen and (max-width: 900px) {
  .landing-footer-class {
    flex-direction: column;
    align-items: left;
  }
  .landing-footer-inner {
    width: 100%;
    align-items: left;
  }
  .img {
    width: 100%;
  }
  .img > img {
    width: 40%;
    height: 40%;
  }
}
