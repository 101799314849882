.footer-main {
  bottom: 0;
  width: 100%;
  position: fixed;
  z-index: 999;
  background: white;
}
.footer-divider {
  width: 100%;
  height: 1px;
  background: black;
  border: 1px solid;
}
.footer-inner {
  flex-wrap: wrap;
  width: 100%;
  display: flex;
  align-items: center;
}
.footer-inner1 {
  flex-wrap: wrap;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.footer-content {
  cursor: pointer;
  padding: 0px 10px;
}
.margin-left {
  margin-left: 27%;
}
.margin-right {
  margin-right: 27%;
}

@media only screen and (max-width: 900px) {
  .margin-left {
    margin-left: 0%;
  }
  .margin-right {
    margin-right: 0%;
  }
}
