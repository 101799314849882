.container {
  padding: 20px;
  background-color: #f9f9f9;
  border: 1px solid #ddd;
  border-radius: 5px;
  width: 95%;
  margin: 20px auto;
}

.header {
  text-align: center;
}

h2 {
  color: #333;
}

p,
ul {
  margin-bottom: 15px;
}

ul {
  list-style-type: disc;
  margin-left: 20px;
}

a {
  color: #007bff;
  text-decoration: none;
}

a:hover {
  text-decoration: underline;
}

.contact-info {
  margin-top: 20px;
  border-top: 1px solid #ccc;
  padding-top: 10px;
}
