.main {
  width: 100%;
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  flex-direction: column;
  background-position: center;
  justify-content: center;
  align-items: center;
  height: 100vh;
  overflow: hidden;
}
.main-inner {
  background: linear-gradient(to bottom, #d4df98 30%, #f5f5f5 20%);
  width: 30rem;
  height: 35rem;
  border: 1px solid #f5f5f5;
  border-radius: 1%;
}
.image-container-outer {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-top: 1rem;
}
.image-container {
  width: 20rem;
  height: 5rem;
}
.image {
  cursor: pointer;
  width: 100%;
  height: 100%;
}
.text-container-outer {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-top: 1rem;
  background: transparent;
}
.text-container {
  background: #f5f5f5;
  width: 80%;
  height: 25rem;
  border: 1px solid #f5f5f5;
  border-radius: 1%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: #473f3fed;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
}
.text-inner {
  width: 80%;
}

/* media query for 500px */
@media only screen and (max-width: 500px) {
  .main-inner {
    width: 20rem;
    height: 35rem;
  }
}
