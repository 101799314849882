.hr-dashboard {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  justify-content: center;
  align-items: center;
}
.hr-dashboard-main {
  display: flex;
  width: 100%;
}

.time {
  color: #daa520;
}

/* media query for 900px */
@media only screen and (max-width: 900px) {
  .hr-dashboard-main {
    padding: 0rem 1rem !important;
    font-size: large !important;
  }
}
