.summary-box {
  background: white;
  display: flex;
  justify-content: space-between;
  width: 240px;
  padding: 1rem;
  color: #4e4e4e;
  border-radius: 20px;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
  transition: 0.3s;
}
.summary-box:hover {
  background: whitesmoke;
  box-shadow: rgba(10, 10, 11, 0.2) 0px 7px 9px 0px;
  transform: scale(1.01);
}
.summary-box .icon1 {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.summary-box__info {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
}
.summary-box__info__title {
  font-size: 1rem;
  font-weight: 700;
  line-height: 1.2;
}
.summary-box__info__title span {
  font-size: 13px;
  font-weight: 400;
}
.summary-box__info__value {
  font-weight: 700;
  font-size: 1.5rem;
  color: #4e4e4e;
}
