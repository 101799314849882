.sidebar-outer {
  align-items: center;
  width: 13.5%;
  position: fixed;
  min-height: 100vh;
  background: #d4df98;
  z-index: 999;
}
.sidebar-main {
  width: 100%;
  height: 100vh;
}
.sidebar-main-inner {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 1rem 0rem;
}
.sidebar-content-item {
  display: flex;
  align-items: center;
  width: 100%;
  cursor: pointer;
  padding: 1rem;
  border-radius: 0.5rem;
  color: #daa520;
  font-weight: 600;
  font-size: small;
  &:hover {
    background-color: #a4b964;
    color: #f5f5f5;
  }
}
.sidebar-content-item span {
  display: flex;
  align-items: center;
  padding: 0.1rem;
}

/* media query for 1200px */
@media only screen and (max-width: 1200px) {
  .sidebar-content-item {
    font-size: x-small;
  }
}
