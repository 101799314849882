.header-top1 {
  padding: 0.5rem 2.5rem;
  background: red;
  color: white;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.contact-info1 {
  display: flex;
  align-items: center;
}
.contact-info1 > div {
  display: flex;
}

.social-icons {
  display: flex;
  align-items: center;
  cursor: pointer;
}

.header-bottom-fix {
  width: 100%;
  z-index: 2;
  top: 0;
  position: fixed;
  transition: top 1s ease-in-out;
}

.header-bottom {
  padding: 0.8rem 2.5rem;
  background: #eaeaea;
  color: #daa520;
  display: flex;
  align-items: center;
  justify-content: space-between;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
}

.logo {
  font-weight: 700;
  font-size: 2rem;
  display: flex;
  align-items: center;
  cursor: pointer;
}

.menu {
  display: flex;
  align-items: center;
}

.menu-item {
  display: flex;
  align-items: center;
  cursor: pointer;
  padding: 0.5rem;
  border-radius: 0.5rem;
  font-weight: 500;
}
.menu-item:hover {
  background-color: #dbdbda;
}

.language-selector {
  display: flex;
  align-items: center;
}
.drawer {
  display: none;
}
.drawer-close {
  margin: 1rem;
  width: 90%;
  display: flex;
  align-items: center;
}

.drawer-close1 {
  margin: 1rem;
  width: 80%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.drawer-content {
  display: flex;
  flex-direction: column;
  width: 100%;
}
.drawer-content-item {
  display: flex;
  align-items: center;
  cursor: pointer;
  padding: 1rem 1.4rem;
  font-size: 0.7rem;
}
.drawer-content-item:hover {
  background-color: #a4b964;
  color: #f5f5f5;
}

.drawer-content-item span {
  display: flex;
  align-items: center;
  padding: 0.1rem;
}
.drawer-nav {
  display: none;
}

@media only screen and (max-width: 900px) {
  .menu,
  .language-selector,
  .social-icons {
    display: none;
  }
  .contact-info1 {
    justify-content: space-between;
    width: 100%;
  }
  .drawer-nav {
    display: flex;
  }
  .drawer {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
  }
  .drawer-inner {
    position: fixed;
    height: 100vh;
    background: #d4df98;
    width: 20%;
    z-index: 999;
    top: 0;
    right: -20%;
    transition: right 0.5s ease-in-out;
  }
  .drawer-inner.open {
    right: 0;
  }
  .drawer-inner1 {
    position: fixed;
    height: 100vh;
    background: #d4df98;
    width: 20%;
    z-index: 999;
    top: 0;
    left: -20%;
    transition: left 0.5s ease-in-out;
  }
  .drawer-inner1.open {
    left: 0;
  }
  .logo {
    font-size: 1.5rem;
  }
}
@media only screen and (max-width: 600px) {
  .drawer-inner {
    width: 27%;
    right: -27%;
  }
  .drawer-inner1 {
    width: 27%;
    left: -27%;
  }
}
