.container {
  width: 95%;
  margin: 20px auto;
  background: #fff;
  padding: 2rem;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

h1 {
  text-align: center;
  color: #333;
}

p {
  color: #666;
  margin-bottom: 20px;
}

.services {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.card {
  background: #fff;
  border: 1px solid #ddd;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  margin: 10px;
  padding: 20px;
  width: calc(33.333% - 20px);
  box-sizing: border-box;
  transition: transform 0.2s;
  text-align: center;
}

.card:hover {
  transform: scale(1.05);
}

.card img {
  width: 70%;
  height: auto;
  border-radius: 10px 10px 0 0;
}
.card p {
  color: #333;
  margin: 0;
}

@media (max-width: 768px) {
  .container {
    width: 95%;
  }

  .card {
    width: calc(50% - 20px);
  }
}

@media (max-width: 480px) {
  .card {
    width: 100%;
  }
}
