* {
  box-sizing: border-box;
  font-family: "Ramabhadra", sans-serif !important;
}
body {
  margin: 0;
  padding: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.global-class {
  margin: 6% 0%;
}
.global-flex-class {
  display: flex;
  width: 100%;
}
.global-main-outer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  flex-direction: column;
}
.global-main {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 98%;
}

/* antd table css */
.status-approved {
  background-color: #dff0d8;
  color: #3c763d;
  font-weight: bold;
  text-align: center;
  text-transform: capitalize; /* Capitalizes the first letter of each word */
  padding: 0.5rem;
  border-radius: 1%;
}

.status-rejected {
  background-color: #f2dede;
  text-transform: capitalize;
  color: #a94442;
  font-weight: bold;
  text-align: center;
  padding: 0.5rem;
  border-radius: 1%;
}

.status-pending {
  background-color: #fcf8e3;
  text-transform: capitalize;
  color: #8a6d3b;
  font-weight: bold;
  text-align: center;
  padding: 0.5rem;
  border-radius: 1%;
}

@media only screen and (max-width: 1200px) {
  .global-class {
    margin: 8% 0%;
  }
}
@media only screen and (max-width: 900px) {
  .global-class {
    margin: 10% 0%;
  }
}
@media only screen and (max-width: 600px) {
  .global-class {
    margin: 18% 0%;
  }
  .global-main {
    flex-direction: column;
  }
}
